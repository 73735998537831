import { Portfolio } from '@netpurpose/api'

export type MultiplePortfolioActionProps = {
  selectedPortfolioIds: Portfolio['id'][]
  setSelectedPortfolioIds: (selectedIds: Portfolio['id'][]) => void
}

export type ModalActionTrigger = {
  onClick: () => void
}

// NOTE: we don't want "fund of funds" to appear in type filters
export const portfolioTypeMap: Record<string, string> = {
  private: 'Portfolio',
  benchmark: 'Benchmark',
  watchlist: 'Watchlist',
  fund: 'Fund',
}
