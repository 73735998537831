import Link from 'next/link'
import { Button } from '@netpurpose/np-ui'
import { convertObjectArrayToCompareQueryParams } from '#components/compare/utils'
import { useAnalytics } from '#context/analytics'
import { AnalyticsButtonName, AnalyticsEventName } from '#types/analytics'

export const CompareButton = ({
  selectedIds,
  type,
  analyticsButtonName,
}: {
  selectedIds: number[]
  type: 'company' | 'portfolio'
  analyticsButtonName: AnalyticsButtonName
}) => {
  const analytics = useAnalytics()

  const objectArray = selectedIds.map((id) => ({
    id: `${id}`,
    type,
  }))

  const queryString = convertObjectArrayToCompareQueryParams(objectArray) ?? ''

  return (
    <Button
      level="secondary"
      size="small"
      onClick={() =>
        analytics?.logEvent(AnalyticsEventName.ButtonClick, {
          button_name: analyticsButtonName,
        })
      }
    >
      <Link href={`/${type === 'company' ? 'explore' : 'portfolios'}/compare?${queryString}`}>
        Compare
      </Link>
    </Button>
  )
}
