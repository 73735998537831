import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { FC, MouseEvent, useCallback, useState } from 'react'
import { Portfolio } from '@netpurpose/api'
import { CheckboxWOutFormik, Icon, NextLink as Link, NavigationTable } from '@netpurpose/np-ui'
import { FilterConfig, PaginationConfig } from '@netpurpose/types'
import { useAnalytics } from '#context/analytics'
import { PortfolioCreationTask } from '#context/tasks'
import { config } from '#services/config'
import { AnalyticsEventName } from '#types/analytics'
import { Tab } from '../PortfolioDetail/types'
import { EmptyPortfolioTable } from './EmptyPortfolioTable'
import { CheckboxContainer, NameCellContainer } from './PortfolioTable.style'
import {
  formatPendingPortfolioRow,
  formatPortfolioRow,
  getColumns,
  getPortfolioIds,
  PortfolioForDisplay,
} from './utils'

const Spaceholder = ({ show }: { show: boolean }) =>
  show ? <div style={{ width: '24px' }} /> : null

const ExpandIcon = ({
  expanded,
  onClick,
}: {
  expanded: boolean
  onClick: (rows: number[]) => void
}) =>
  expanded ? (
    <Icon icon="ChevronDown" alt="collapse row" onClick={() => onClick} color="black" />
  ) : (
    <Icon icon="ChevronRight" alt="expand row" onClick={() => onClick} color="black" />
  )

const PortfolioLink: FC<{ record: PortfolioForDisplay; children: React.ReactNode }> = ({
  record,
  children,
}) => {
  const href = record?.parentPortfolioId
    ? config.dynamicRoutes.portfolioFundDetail(record.parentPortfolioId, record.id)
    : config.dynamicRoutes.portfolioDetail(
        record.id,
        record.type === 'fund of funds' ? Tab.Impact : Tab.SDGs,
      )
  return (
    <Link
      href={href}
      // NOTE: this is to prevent expandRowByClick from triggering
      // @ts-expect-error
      onClick={(e: MouseEvent) => e.stopPropagation()}
    >
      {children}
    </Link>
  )
}

const PortfolioLinkCell = ({
  record,
  children,
  selectedPortfolioIds,
  setSelectedPortfolioIds,
  expandedRowKeys,
  updateExpandedRows,
}: {
  record: PortfolioForDisplay
  children: React.ReactNode
  selectedPortfolioIds: Portfolio['id'][]
  setSelectedPortfolioIds: (keys: Portfolio['id'][]) => void
  expandedRowKeys: number[] | undefined
  updateExpandedRows: (rows: number[]) => void
}) => {
  const expanded = expandedRowKeys?.includes(record.id)
  const showExpandIcon = !!record.children?.length
  const isFund = record.type === 'fund'
  const isNestedFund =
    isFund && record?.parentPortfolioId && expandedRowKeys?.includes(record?.parentPortfolioId)
  return (
    <NameCellContainer>
      <CheckboxContainer onClick={(e: MouseEvent) => e.stopPropagation()}>
        <CheckboxWOutFormik
          checked={selectedPortfolioIds.includes(record.id)}
          onChange={({ target: { checked } }: CheckboxChangeEvent) => {
            setSelectedPortfolioIds(
              getPortfolioIds({
                newSelectedRowId: record.id,
                selectedPortfolioIds,
                isBeingChecked: checked,
              }),
            )
          }}
          aria-label={record.name}
          disabled={record.resultStatus === 'pending'}
        />
      </CheckboxContainer>
      {showExpandIcon ? (
        <ExpandIcon expanded={!!expanded} onClick={() => updateExpandedRows([record.id])} />
      ) : (
        <Spaceholder show={!isFund || !isNestedFund} />
      )}
      <PortfolioLink record={record}>{children}</PortfolioLink>
    </NameCellContainer>
  )
}

type Props = {
  portfolios?: Portfolio[]
  pendingPortfolios?: PortfolioCreationTask[]
  isLoading: boolean
  selectedPortfolioIds: Portfolio['id'][]
  setSelectedPortfolioIds: (keys: Portfolio['id'][]) => void
  filterConfig: FilterConfig<Portfolio>
  paginationConfig: PaginationConfig
}

export const PortfolioTable: FC<Props> = ({
  portfolios,
  pendingPortfolios = [],
  isLoading,
  selectedPortfolioIds,
  setSelectedPortfolioIds,
  filterConfig,
  paginationConfig,
}) => {
  const displayData = [
    ...pendingPortfolios.map(formatPendingPortfolioRow),
    ...(portfolios || []).map(formatPortfolioRow),
  ]
  const analytics = useAnalytics()

  const logTooltipHoverEvent = (tooltipName: string) =>
    analytics?.logEvent(AnalyticsEventName.Hover, {
      table_name: 'Portfolio data',
      hover_item_name: `Tooltip - ${tooltipName}`,
    })

  const [expandedRows, setExpandedRows] = useState<Map<PaginationConfig['currentPage'], number[]>>(
    new Map<PaginationConfig['currentPage'], number[]>(),
  )

  const updateExpandedRows = useCallback(
    (rows: number[]) =>
      setExpandedRows((prevMap) => new Map(prevMap.set(paginationConfig?.currentPage || 1, rows))),
    [paginationConfig?.currentPage],
  )

  const expandedRowKeys = expandedRows.get(paginationConfig?.currentPage || 1)

  return (
    <NavigationTable<PortfolioForDisplay>
      rowKey={(row) => row.rowId}
      rowClassName={(row) => (row.resultStatus === 'complete' ? '' : 'disabledRow')}
      dataSource={displayData}
      columns={getColumns({
        paginationConfig,
        filterConfig,
        logTooltipHoverEvent,
        expandedRowKeys,
      })}
      loading={isLoading}
      renderLink={({ record, children }) => (
        <PortfolioLinkCell
          record={record}
          children={children}
          selectedPortfolioIds={selectedPortfolioIds}
          setSelectedPortfolioIds={setSelectedPortfolioIds}
          expandedRowKeys={expandedRowKeys}
          updateExpandedRows={updateExpandedRows}
        />
      )}
      linkColumnDataIndex="name"
      locale={{ emptyText: EmptyPortfolioTable }}
      pagination={paginationConfig.tablePaginationConfig}
      onChange={paginationConfig.handleTableChange}
      data-testid="portfolios-table"
      expandable={{
        indentSize: 32,
        expandIcon: () => null,
        ...(expandedRowKeys ? { expandedRowKeys } : {}),
        onExpandedRowsChange: (expandedKeys) => updateExpandedRows(expandedKeys as number[]),
        expandRowByClick: true,
      }}
      renderLastColumnLink={PortfolioLink}
    />
  )
}
